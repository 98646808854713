import { Component, Input, OnInit } from '@angular/core';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { environment } from '@base/environments/environment';

@Component({
  selector: 'qr-media-img',
  templateUrl: './qr-media-img.component.html',
  styleUrls: ['./qr-media-img.component.scss'],
  standalone: true,
})
export class QrMediaImgComponent implements OnInit {
  @Input() config!: QRMediaImage;
  readonly imageCloudfront: string = environment.imageCloudfront;
  public isLazyLoading: boolean = false;

  ngOnInit(): void {
    this.isLazyLoading = this.config.lazyLoading === QRMediaImage.LOADING_LAZY;
  }
}
